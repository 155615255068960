import React from 'react';

const StarRating = ({ rating, onRatingChange, userRating }) => {
  console.log(rating,userRating,"userRating")
  const stars = Array.from({ length: 5 }, (_, index) => (
    <span
      key={index}
      disabled={userRating ? true : false}
      onClick={() => onRatingChange(index + 1)}
      style={{ cursor: 'pointer' }}
    >
      {rating ?  (index < rating ? '★' : '☆'):(index < userRating ? '★' : '☆')}
    </span>
  ));

  return <div>{stars}</div>;
};

export default StarRating;