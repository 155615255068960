import React from "react";
import Auth from "../../../libs/auth";
import SellerRating from "./SellerRating";
import BuyerRating from "./BuyerRating";

const RatingPage = ({
  sellerRatings,
  buyerRatings,
  handleBuyerRatingChange,
  handleSellerRatingChange,
  userData,
  rating,
}) => {
  const user = Auth.getCurrentUser();

  return (
    <div className="order-table">
      <table
        style={{
          width: "50%",
          margin: "0 auto",
        }}
      >
        <thead>
          <tr>
            <th colSpan="2">{`${
              user?.is_seller === 1 ? "Seller to Buyer" : "Buyer to Seller"
            }`}</th>
          </tr>
        </thead>
        {userData?.is_seller === 1 ? (
          <SellerRating
            userDataId={userData?.id}
            rating={rating}
            ratings={sellerRatings}
            handleRatingChange={handleSellerRatingChange}
          />
        ) : (
          <BuyerRating
            userDataId={userData?.id}
            rating={rating}
            ratings={buyerRatings}
            handleRatingChange={handleBuyerRatingChange}
          />
        )}
      </table>
    </div>
  );
};

export default RatingPage;
