import React from 'react'
import StarRating from './StarRatings'

const SellerRating = ({ratings, handleRatingChange, rating, userDataId}) => {

  const orderRating = rating?.find((item) => item?.user_id === userDataId);
  const respond = orderRating?.reviews[0]?.review;
  const payment = orderRating?.reviews[1]?.review;
  const support = orderRating?.reviews[2]?.review;
  const valuable = orderRating?.reviews[3]?.review;
  return (
    <>
   <tbody>
          <tr>
            <td>Respond</td>
            <td style={{
          color: "#FFBE26",
         }}>
              <StarRating
              userRating={respond}
                rating={ratings.respond}
                onRatingChange={(rating) => handleRatingChange("respond", rating)}
              />
            </td>
          </tr>
          <tr>
            <td>Payment</td>
            <td style={{
          color: "#FFBE26",
         }}>
              <StarRating
              userRating={payment}
                rating={ratings.payment}
                onRatingChange={(rating) =>
                  handleRatingChange("payment", rating)
                }
              />
            </td>
          </tr>
          <tr>
            <td>Support</td>
            <td style={{
          color: "#FFBE26",
         }}>
              <StarRating
              userRating={support}
                rating={ratings.support}
                onRatingChange={(rating) =>
                  handleRatingChange("support", rating)
                }
              />
            </td>
          </tr>
          <tr>
            <td>Valuable</td>
            <td style={{
          color: "#FFBE26",
         }}>
              <StarRating
              userRating={valuable}
                rating={ratings.valuable}
                onRatingChange={(rating) =>
                  handleRatingChange("valuable", rating)
                }
              />
            </td>
          </tr>
        </tbody>
   </>
  )
}

export default SellerRating