import React, { useState } from "react";
import StarRating from "./StarRatings";

const BuyerRating = ({ ratings, handleRatingChange, rating, userDataId }) => {

  const orderRating = rating?.find((item) => item?.user_id === userDataId);
  const price = orderRating?.reviews[0]?.review;
  const quality = orderRating?.reviews[1]?.review;
  const service = orderRating?.reviews[2]?.review;
  const support = orderRating?.reviews[3]?.review;

  return (
    <>
      <tbody>
        <tr>
          <td>Price</td>
          <td style={{
          color: "#FFBE26",
         }}>
            <StarRating
              userRating={price}
              rating={ratings.price}
              onRatingChange={(rating) => handleRatingChange("price", rating)}
            />
          </td>
        </tr>
        <tr>
          <td>Quality</td>
          <td style={{
          color: "#FFBE26",
         }}>
            <StarRating
              userRating={quality}
              rating={ratings.quality}
              onRatingChange={(rating) => handleRatingChange("quality", rating)}
            />
          </td>
        </tr>
        <tr>
          <td>Service</td>
          <td style={{
          color: "#FFBE26",
         }}>
            <StarRating
              userRating={service}
              rating={ratings.service}
              onRatingChange={(rating) => handleRatingChange("service", rating)}
            />
          </td>
        </tr>
        <tr>
          <td>Support</td>
          <td style={{
          color: "#FFBE26",
         }}>
            <StarRating
              userRating={support}
              rating={ratings.support}
              onRatingChange={(rating) => handleRatingChange("support", rating)}
            />
          </td>
        </tr>
      </tbody>
    </>
  );
};

export default BuyerRating;
